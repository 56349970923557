/**
 * @generated SignedSource<<991fa72b8420c20f2de3f03e6c172c35>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type HeroBrandLogoLayout = "BELOW" | "SIDE";
export type HeroBrandLogoText = "HIDE" | "SHOW";
export type HeroBrandLogoType = "DEFAULT" | "INVERTED";
import { FragmentRefs } from "relay-runtime";
export type HeroBrandLogo_contentBlock$data = {
  readonly brandLayout: HeroBrandLogoLayout;
  readonly brandText: HeroBrandLogoText;
  readonly brandType: HeroBrandLogoType;
  readonly " $fragmentType": "HeroBrandLogo_contentBlock";
};
export type HeroBrandLogo_contentBlock$key = {
  readonly " $data"?: HeroBrandLogo_contentBlock$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeroBrandLogo_contentBlock">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeroBrandLogo_contentBlock",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "brandType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "brandLayout",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "brandText",
      "storageKey": null
    }
  ],
  "type": "HeroBrandLogoBlock",
  "abstractKey": null
};

(node as any).hash = "3f29d3f83b4941b93041adf79f8f7ae8";

export default node;
