import type { FC } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import styled from '@emotion/styled';
import { Breakpoint } from '@pafcloud/style';
import { Logo as DefaultLogo } from '@pafcloud/base-components';
import type { HeroBrandLogo_contentBlock$key } from './__generated__/HeroBrandLogo_contentBlock.graphql';

const contentBlockFragment = graphql`
  fragment HeroBrandLogo_contentBlock on HeroBrandLogoBlock {
    brandType
    brandLayout
    brandText
  }
`;

const Logo = styled(DefaultLogo)<{ onlyIcon: boolean }>(
  {
    maxWidth: '25vh',
    maxHeight: '150px',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  ({ onlyIcon }) =>
    onlyIcon && {
      maxWidth: '15vh',
      maxHeight: '15vw',
      [Breakpoint.LaptopOrLarger]: {
        maxWidth: 100,
        maxHeight: 100,
      },
    },
);

type HeroButtonComponentProps = {
  contentBlock: HeroBrandLogo_contentBlock$key;
};

const HeroBrandLogo: FC<HeroButtonComponentProps> = ({ contentBlock }) => {
  const { brandType, brandLayout, brandText } = useFragment(contentBlockFragment, contentBlock);

  const variant = () => {
    if (brandLayout === 'BELOW') {
      return 'vertical';
    }

    if (brandText === 'HIDE') {
      return 'icon';
    }

    return 'horizontal';
  };

  return <Logo inverse={brandType === 'INVERTED'} variant={variant()} onlyIcon={brandText === 'HIDE'} />;
};

export default HeroBrandLogo;
